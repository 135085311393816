import {APP_INITIALIZER, ApplicationConfig, ErrorHandler, provideZoneChangeDetection} from '@angular/core';
import {provideRouter, Router} from '@angular/router';

import { AppRoutes } from './app.routes';
import {authHttpInterceptorFn, provideAuth0} from "@auth0/auth0-angular";
import {provideAnimations} from "@angular/platform-browser/animations";
import * as Sentry from "@sentry/angular";
import {environment} from "../environments/environment";
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {API_BASE_URL} from "../sysfra";
import {MessageService} from "primeng/api";

export const appConfig: ApplicationConfig = {
  providers: [
    MessageService,
    provideHttpClient(withInterceptors([authHttpInterceptorFn])),
    provideAnimations(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(AppRoutes),
    provideAuth0({
      domain: 'auth.dxura.com',
      clientId: 'tY2HuZr8gh049l9DvWf4wdxBwb7OAPXy',

      authorizationParams: {
        redirect_uri: window.location.origin,

        // Request this audience at user authentication time
        audience: 'https://api.sysfra.com',
      },

      // Specify configuration for the interceptor
      httpInterceptor: {
        allowedList: [
          environment.API_URL + '/*'
        ]
      }
    }),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: API_BASE_URL,
      useValue: environment.API_URL,
    },
  ],
};
