<div (click)="notificationOverlay.toggle($event)"  class="flex items-center justify-center hover:scale-95 transition cursor-pointer mx-2">
  <i pBadge severity="danger" value="2" class="pi pi-bell text-2xl text-white p-badge-lg"></i>
</div>

<p-overlayPanel #notificationOverlay>
  <div class="w-full h-full">
    <ul>
      <li>
        Not implemented yet lol
      </li>
      <li>
        Not implemented yet lol
      </li>
      <li>
        Not implemented yet lol
      </li>
    </ul>
  </div>
</p-overlayPanel>
